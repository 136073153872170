<template>
    <div class="text-center">
        <template>
            <v-snackbar
                v-model="snackbar.show"
                :color="snackbar.color"
                :timeout=snackbarTimeout
                top
                right
                >
                <v-icon class="mb-1">{{`mdi-${snackbar.icon}`}}</v-icon>
                {{snackbar.text}}
            </v-snackbar>

            <v-snackbar
                class="mt-15"
                v-model="snackbar.isUpdated"
                :color="snackbar.color"
                :timeout="-1"
                top
                right
                >
                {{ snackbar.text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="refreshPage()"
                    >
                    REFRESH
                    </v-btn>
                </template>
            </v-snackbar>
        </template>
    </div>

</template>
<script>
    export default {
        props:{
            snackbar:{
                type: Object
            }
        },

        data: () => ({
            snackbarTimeout: 1500,
        }),
        methods: {
            refreshPage() {
                this.$emit('refreshPage');
            }
        },
    }
</script>