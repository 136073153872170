/* eslint-disable no-console */

import { register } from 'register-service-worker'
import swal from "sweetalert2"

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      swal.fire({
        icon: 'info',
        title: 'Information',
        allowOutsideClick: false,
        showConfirmButton:false,
        html: `<h3>New System Update Available.</h3><br>
          <span>Kindly <b style='color:red;'>CLOSE THE BROWSER TAB</b></span><br>
          <span>then <b style='color:red;'>RE-OPEN</b> the system</span><br>
          <span>Thankyou</span>
        `
      })

    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
