import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import _ from 'lodash'
import axios from 'axios';
const dotenv = require('dotenv');
dotenv.config();

Vue.use(Vuex)

const persistedData = new createPersistedState({
    key:'gc_tsuika',
    storage: localStorage,
    reducer : state => ({
      translation:  state.translation,
      editMode: state.editMode,
      selectAll: state.selectAll,
      headerCheckbox: state.headerCheckbox,
      activeMode: state.activeMode,
      loggedInUser: state.loggedInUser,
      isFromGc: state.isFromGc,
      prevConstructionCode: state.prevConstructionCode,
      hasNewUpdate: state.hasNewUpdate,
      updateFullAdmin: state.updateFullAdmin,
    })
})



export default new Vuex.Store({
  plugins: [persistedData],
  state: {
    translation: null,
    loggedInUser: {},

    editMode: false,
    selectAll: false,
    selectedRows: [],
    headerCheckbox: false,
    activeMode: false,
    isFromGc: false,
    search: '',

    //test
    limit: 50,
    industryCategoryData: [],
    industyCategoryRecords: 0,
    accessRightData: [],
    tsuikaData: [],
    versionData:[],
    rules: {
      required: [value => !!value || "Field is required"],
      email: [value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }]
    },
  userNumberData: [],
  tsuikaUserData: [],
  industryData: [],
  emailData: [],
  prevConstructionCode: null,
  fullAdminData: [],
  subGroupData: [],
  accessViewData: [],
  leadTimeData: [],
  leadFormDayData: [],
  hasNewUpdate: false,
  updateFullAdmin: false,
  categoryEmails: [
    {
      "id": "101",
      "department": "FRAME",
      "emailAddress": "mpcad@hrd-s.com"
    },
    {
      "id": "351",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "363",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "368",
      "department": "SUIDOU",
      "emailAddress": "hac-haikan@hrd-s.com"
    },
    {
      "id": "410",
      "department": "UNIT WIRING",
      "emailAddress": "denki-dept@hrd-s.com"
    },
    {
      "id": "537",
      "department": "UNIT WIRING",
      "emailAddress": "denki-route@hrd-s.com"
    },
    {
      "id": "204",
      "department": "SUIDOU",
      "emailAddress": "hac-haikan@hrd-s.com"
    },
    {
      "id": "208",
      "department": "SUIDOU",
      "emailAddress": "hac-haikan@hrd-s.com"
    },
    {
      "id": "209",
      "department": "SUIDOU",
      "emailAddress": "hac-haikan@hrd-s.com"
    },
    // {
    //   "id": "305",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },
    // {
    //   "id": "306",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },
    // {
    //   "id": "327",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },
    {
      "id": "305",
      "department": "DENKI",
      "emailAddress": ['denki-counting@hrd-s.com','g-arevalo@hrd-s.com']
    },
    {
      "id": "306",
      "department": "DENKI",
      "emailAddress": ['denki-counting@hrd-s.com','g-arevalo@hrd-s.com']
    },
    {
      "id": "327",
      "department": "DENKI",
      "emailAddress": ['denki-routedesign@hrd-s.com','g-arevalo@hrd-s.com']
    },
    {
      "id": "333",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "349",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "356",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "357",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "359",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "362",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "365",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "348",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    {
      "id": "536",
      "department": "HACCHU",
      "emailAddress": "gaibuhiroi@hrd-s.com"
    },
    {
      "id": "554",
      "department": "HACCHU",
      "emailAddress": "interior09@hrd-s.com"
    },
    // {
    //   "id": "541",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },
    // {
    //   "id": "746",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },
    // {
    //   "id": "747",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },
    // {
    //   "id": "748",
    //   "department": "DENKI",
    //   "emailAddress": "r-angkico@hrd-s.com"
    // },  
    {
      "id": "541",
      "department": "DENKI",
      "emailAddress": ['denki-counting@hrd-s.com','g-arevalo@hrd-s.com']
    },
    {
      "id": "746",
      "department": "DENKI",
      "emailAddress": ['denki-routedesign@hrd-s.com','g-arevalo@hrd-s.com']
    },
    {
      "id": "747",
      "department": "DENKI",
      "emailAddress": ['denki-routedesign@hrd-s.com','g-arevalo@hrd-s.com']
    },
    {
      "id": "748",
      "department": "DENKI",
      "emailAddress": ['denki-routedesign@hrd-s.com','g-arevalo@hrd-s.com']
    },  
  ],

  },
  mutations: {
    STORE_USERINFO: (state, newState) => {
      state.userInfo = newState
    },
    STORE_ACCOUNTS: (state, newState) => {
      state.accounts = newState
    },
    'TRANSLATE': (state, newState) => {
      state.translation = newState
    },
    LOGGEDIN_USER: (state, newState) => {
      state.loggedInUser = newState;
    },
    TOGGLE_EDIT_MODE: (state, newState) => {
      state.editMode = newState
      state.selectAll = false,
      state.selectedRows.length = 0
      state.headerCheckbox = !newState
    },
    prevConstructionCode: (state, newState) => {
      state.prevConstructionCode = newState;
    },
    hasNewUpdate: (state, newState) => {
      state.hasNewUpdate = newState;
    },
    updateFullAdmin: (state, newState) => {
      state.updateFullAdmin = newState;
    },

    setSelectedRow(state, payload) {
      state.selectAll = false;

      if(payload.isChecked) {
        state.selectedRows.push(payload);
      }else{
        const index = _.findIndex(state.selectedRows, ((x) => x.BUZAI_CD == payload.BUZAI_CD))
        state.selectedRows.splice(index, 1);
      }
    },

    isFromGc(state, payload) {
      state.isFromGc = payload;
    },
    industryCategoryPage(state, payload) {
      var len = state.industryCategoryData.length;
      if(len > 0) {
          for(let i = 0; i <= payload[0].length; i++) {
              if(payload[0][i]) {
                state.industryCategoryData.push(payload[0][i])
              }
          }
      } else {
          state.industryCategoryData = payload[0];
          state.industyCategoryRecords = payload[1];
          
      }
    },
    getAccessRights(state, payload){
      state.accessRightData = payload;
    },
    getTsuikaData(state, payload) {
      state.tsuikaData = payload;
    },
    getUserNumberData(state, payload) {
      state.userNumberData = payload;
    },
    getTsuikaUsers(state, payload) {
      state.tsuikaUserData = payload;
    },
    loadCategoryData(state, payload) {
      state.industryData = payload;
    },
    getEmail(state, payload) {
      state.emailData = payload;
    },
    getFullAdmin(state, payload) {
      state.fullAdminData = payload
    },
    getSubGroup(state, payload) {
      state.subGroupData = payload;
    },
    getAccessView(state, payload) {
      state.accessViewData = payload;
    },
    getLeadTime(state, payload) {
      state.leadTimeData = payload;
    },
    getLeadFormDay(state, payload) {
      state.leadFormDayData = payload;
    },

    getVersion(state, payload) {
      state.versionData = payload;
    }

    // S3_FILES(state, payload) { //kapag magbabago lang ng data
    //   state.S3files = payload;
    // },
  },
  actions: {
    getTranslate({ commit }, data) {
      commit("TRANSLATE", data)
    },
    TOGGLE_EDIT_MODE({ commit }, value) {
      commit("TOGGLE_EDIT_MODE", value)
    },

    setSelectedRow({ commit }, data) {
      commit('setSelectedRow', data)
    },
    async industryCategoryPage({ commit, state }, payload) {
      await axios.post(`${window.api}/hrdmssql/getCategoryData`, {
        offset: state.industryCategoryData.length,
        limit: state.limit,
        additional_industry_id: payload
      })
        .then(res => {
          commit('industryCategoryPage', res.data);
        })
    },
    async getAccessRights({commit}) {
      await axios({
          method: 'get',
          url: `${window.api}/mysql/getAccessRights`,
          headers: {
            'x-api-key' : process.env.VUE_APP_HEADERS
          }
      }).then(res => {
        commit('getAccessRights', res.data)
      })
    },
    async getTsuikaData({ commit }) {
      await axios ({
        method: 'get',
        url: `${window.api}/mysql/getTsuika`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getTsuikaData', res.data)
      })
    },
    async getUserNumberData({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/user-number/fetch`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getUserNumberData', res.data)
      })
    },
    async getTsuikaUsers({ commit }) {
      let url = '', header = '';
      
      header = process.env.VUE_APP_ACTUAL_PORTAL_HEADERS
      // url = 'https://9rk5no11y2.execute-api.us-east-2.amazonaws.com/prod/users/getTsuikaUsers'
      url = 'https://2ivydv5gu3.execute-api.us-east-2.amazonaws.com/prod/users/getTsuikaUsers'

      this.loadMore = true;
      await axios({
        method: 'get',
        url: url,
        headers: {
          'x-api-key' : header
        }
      }).then(res => {
        commit('getTsuikaUsers', res.data)
      })
    },
    async loadCategoryData({ commit }){
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getIndustry`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('loadCategoryData', res.data)
      })
    },
    async getEmail({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getEmail`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getEmail', res.data)
      })
    },
    async getFullAdmin({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getFullAdmin`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getFullAdmin', res.data);
      })
    },
    async getSubGroup({ commit }) {
      let url = '', header = '';
      
      if(process.env.NODE_ENV == 'development') {
        header = process.env.VUE_APP_HEADERS
        url = 'https://taclyz7wi8.execute-api.us-east-2.amazonaws.com/prod/GCSubgroupCategories' //for gc portal test bucket api
      } else {
        header = process.env.VUE_APP_ACTUAL_PORTAL_HEADERS
        // url = 'https://9rk5no11y2.execute-api.us-east-2.amazonaws.com/prod/GCSubgroupCategories'
        url = 'https://2ivydv5gu3.execute-api.us-east-2.amazonaws.com/prod/GCSubgroupCategories'
      }

      await axios({
        method: 'get',
        url: url,
        headers: {  
          'x-api-key' : header
        }
      }).then(res => {
        commit('getSubGroup', res.data);
      })
      
    },
    async getAccessView({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getAccessView`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getAccessView', res.data);
      })
    },
    async getLeadTime({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getLeadTime`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getLeadTime', res.data);
      })
    },
    async getLeadFormDay({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getLeadFormDay`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        commit('getLeadFormDay', res.data);
      })
    },

    async getVersion({ commit }) {
      await axios({
        method: 'get',
        url: `${window.api}/mysql/getVersion`,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        // console.log(res.data,'version');
        commit('getVersion', res.data);
      })
    },
  },
  modules: {
  }
})
