import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      id: 1
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      id: 2
    }
  },
  {
    path: '/version',
    name: 'Version Logs',
    component: () => import('../views/VersionLogs'),
    meta: {
      id: 2
    }
  },
  {
    path: '/email_master',
    name: 'Email Master',
    component: () => import('../views/Master Maintenance/EmailMaster.vue'),
    meta: {
      id: 3
    }
  },
  {
    path: '/industry_master',
    name: 'Industry Master',
    component: () => import('../views/Master Maintenance/IndustryMaster.vue'),
    meta: {
      id: 4
    }
  },
  {
    path: '/industry_category/:id',
    name: 'IndustryCategory',
    component: () => import('../views/Master Maintenance/IndustryCategory.vue'),
    meta: {
      id: 5
    }
  },
  {
    path: '/gc_form',
    name: 'GC',
    component: () => import('../views/forms/GcForm.vue'),
    meta: {
      id: 6
    }
  },
  {
    path: '/admin_settings',
    name: 'Admin Settings',
    component: () => import('../views/AccessSettings/AdminSettings.vue'),
    meta: {
      id: 7
    }
  },
  {
    path: '/user_settings',
    name: 'User Settings',
    component: () => import('../views/AccessSettings/UserSettings.vue'),
    meta: {
      id: 8 
    }
  },
  {
    path: '/full_admin',
    name: 'Full Admin',
    component: () => import('../views/AccessSettings/FullAdmin.vue'),
    meta: {
      id: 9
    }
  },
  {
    path: '/access_view',
    name: 'Access View',
    component: () => import('../views/AccessSettings/AccessView'),
    meta: {
      id: 10
    }
  },
  {
    path: '/minimal_lead_time',
    name: 'Minimal Lead Time',
    component: () => import('../views/AccessSettings/MinimalLeadTime'),
    meta: {
      id: 11
    }
  },
  {
    path: '/order_history',
    name: 'Order History',
    component: () => import('../views/Master Maintenance/OrderHistory'),
    meta: {
      id: 12
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // if (to.path !== '/login') {
  //   if (store.state.loggedInUser != null) {
  //     next()
  //   } else {
  //     next('login')
  //   }
  // } else {
  //   if (store.state.loggedInUser != null) {
  //     next('/')
  //   } else {
  //     next()
  //   }
  // }

  if(!Object.keys(store.state.loggedInUser).length) {
    if(to.path !== "/login") {
      return next({path: "/login"});
    }else{
      next();
    }
  }
  if(Object.keys(store.state.loggedInUser).length > 1) {
    if(store.state.loggedInUser.access.includes(to.meta.id)) {
      if(to.path !== "/login") {
          return next();
      }else{
        return next({path: "/"});
      }
    }
  }

})



export default router
