<template>
  <v-app>
    <v-app-bar
      id="app_bar"
      clipped-left
      dark
      app
      fixed
      v-if="$route.path !== '/login'"
    >
      <v-app-bar-nav-icon
      @click="manageDrawer()"
        style="color: white"
      ></v-app-bar-nav-icon>
      
      <v-toolbar-title style="color: white; font-size: 18px"
        ><img src="./images/gc_tsuika_logo.png" alt="" height="40px">
        {{ $t("global.gcTsuikaHacchuSystem")}}&nbsp;&nbsp;&nbsp;
        <v-btn to="/version" class="mt-1" style="font-size: 13px; color: white" plain>
          <sup>{{ $t("global.version") }} {{ versionNumber }}</sup>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="custom-btn"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            text
          >
          {{ $t("login.manual")}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <!-- <a style="font-size: 10px !important;" 
                href="https://s3.ap-southeast-1.amazonaws.com/gctsuika.hrd-s.com/user_manual/HRDUserManual.pdf" target="_blank">
                {{ $t("login.manualhrd_login")}}
              </a> -->
              <span @click="hrdManual()" style="font-size: 10px !important; cursor: pointer;">{{ $t("login.manualhrd_login")}}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <!-- <a style="font-size: 10px !important;" 
                href="https://s3.ap-southeast-1.amazonaws.com/gctsuika.hrd-s.com/user_manual/GCUserManual.pdf" target="_blank">
                {{ $t("login.manualGc_login")}}
              </a> -->
              <span @click="gcManual()" style="font-size: 10px !important; cursor: pointer;">{{ $t("login.manualGc_login")}}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        
      </v-menu> |




      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="custom-btn"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            text
          >
            {{ $t("lang") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in Language"
            :key="index"
            @change="LanguageSwitcher(item.lang)"
            item-value="lang"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
        
      </v-menu>
      <span class="mr-4">|</span>

      <v-tooltip bottom>
            <template v-slot:activator="{ on : tooltip }">
            <v-menu offset-y max-width="300">
                <template v-slot:activator="{ on : menu, attrs }">
 
                <template v-if="!loggedInUser.phone && !loggedInUser.fax_number">
                  <v-badge
                    dot
                    color="error"
                    overlap
                  >
                    <v-btn
                      text
                      class="white--text"
                      depressed
                      v-on="{ ...tooltip, ...menu }"
                      v-bind="attrs"
                    >
                      {{!loggedInUser ? '' : loggedInUser.user_name}}
                    </v-btn>
                  </v-badge>
                </template>
                <template v-else>

                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                  <v-icon>mdi-account-details</v-icon>
                    {{!loggedInUser ? '' : loggedInUser.user_name}}
                  </v-btn>
                </template>
                
                </template>
                
                <v-list dense>
                    <p class="mt-2" style="font: 12px !important; text-align: center !important; display: block !important; color: #979797FF !important;">{{!loggedInUser ? '' : loggedInUser.email}}</p>
                    <p style="text-align: center !important; display: block !important; margin-top: -17px !important;">
                      <!-- <span style="font-size: 10px !important; color: #979797FF !important; font-weight: bolder;">( {{!loggedInUser ? '' : loggedInUser.account_type}} )</span></p> -->
                      <span style="font-size: 10px !important; color: #979797FF !important; font-weight: bolder;">
                        {{ `( ${!loggedInUser ? '' : loggedInUser.account_type} ${`${!loggedInUser.sub_group ? '' : `- ${loggedInUser.sub_group }`}`} )` }}
                      </span>
                    </p>
                    <v-list-item-group>

                      <v-list-item @click="editAccount(loggedInUser)">
                        <v-list-item-icon>
                            <v-icon style="color: #0C2D48; font-size: 18px;">mdi-account-cog-outline</v-icon>
                        </v-list-item-icon>
                            <span class="mt-1" style="font-size: 12px !important; margin-left: -8px;">{{ $t('global.settings') }}</span>
                      </v-list-item>

                      <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon small style="color: #0C2D48;">mdi-logout</v-icon>
                        </v-list-item-icon>
                          <span class="mt-1" style="font-size: 12px !important; margin-left: -8px;">{{ $t('global.logout') }}</span>
                      </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            </template>
        <span>Account Settings</span>
        </v-tooltip>


    </v-app-bar>
    <v-navigation-drawer
      v-model="myDrawer"
      v-if="$route.path !== '/login'"
      :clipped="$vuetify.breakpoint.lgAndUp"
      color="#f1f3f4"
      dense
      app
      style="border-right: 1px solid rgb(227, 227, 227);"
    >

      <v-list>
        <v-list-item-group color="primary">
          <v-list-item :to="item.to" v-for="(item, i) in filteredNavigation" :key="i" link>
            <v-list-item-icon>
              <v-icon style="margin-top: -4px !important">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t(item.title)}}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="access.to" v-for="(access, userInd) in filteredAccessSettings" :key="userInd + 'accessSettings'" link>
            <v-list-item-icon>
              <v-icon style="margin-top: -4px !important">{{access.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t(access.title)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        
      </v-list>

    </v-navigation-drawer>
    
    <v-dialog
      v-model="warningDialog"
      persistent
      width="350"
      @keydown.esc="warningDialog = false"
      scrollable
    >
      <v-card>
        <v-card-title class="card-title">
          <v-icon color="red" class="mr-1">mdi-alert-circle-outline</v-icon>
          <span class="title-headline">WARNING</span>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="warningDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="card-text">
          <v-container>
            <center><h3 style="color: #666666">Data have beed updated.</h3></center>
            <center><h5 style="color: #666666; margin-top: -8px !important">( Please re-login your account )</h5></center>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-row justify-center> -->
      <v-dialog
        justify="center"
        v-model="editAccountSettings"
        persistent
        width="450"
        scrollable
        @keydown.esc="editAccountSettings = false;"
      >
        <v-card>
          <v-card-title class="card-title">
            <v-icon dark class="mr-2">mdi-card-account-details-outline</v-icon>
            <span class="title-headline">Edit Data</span>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-icon dark @click="editAccountSettings = false;">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mt-1">
                <v-col cols="6">
                  <v-text-field
                    v-model="edit.tempUserID"
                    label="User ID"
                    dense
                    outlined
                    persistent-placeholder
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="edit.tempUserName"
                    label="User Name"
                    dense
                    outlined
                    persistent-placeholder
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="edit.tempPhone"
                    label="Phone"
                    dense
                    outlined
                    persistent-placeholder
                    onKeyPress="if(this.value.length==12) return false;"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="edit.tempFaxNumber"
                    label="Fax Number"
                    v-mask="faxMasked"
                    dense
                    outlined
                    persistent-placeholder
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <hr>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateUserData" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" :disabled="isDisabled" dense>{{ $t('global.submit') }}</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- </v-row> -->

    <snack-bar :snackbar="snackbar" @refreshPage="refreshPage"></snack-bar>

    <v-overlay :value="loadMore" overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapActions } from "vuex";
import axios from 'axios'
import Swal from "sweetalert2";
import SnackBar from '../src/views/includes/SnackBar.vue'

export default {
  name: "App",
  components: {
    "snack-bar": SnackBar,
  },

  sockets: {
    updateGcTsuikaData: function(socket) {
      if(this.$route.path != '/login') { //check if page is in login page
        if(socket.system == 'gc-tsuika' && socket.dispatchType == 'request-local') {
          
          if(socket.request == 'accessRightsUpdate') {
            const user = this.loggedInUser;


            if(socket.requestData != user.user_id) {
              this.$store.commit('hasNewUpdate', true);
              this.lastUserUpdate = socket.requestData;
              this.getAccessRights();

              if(window.location.hostname != 'gctsuika.hrd-s.com') { //actual: gctsuika.hrd-s.com testbucket:gctsuika.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com
                this.snackbar.isUpdated = true;
              }

            } else {
              this.$store.commit('hasNewUpdate', false);
              this.$store.commit('updateFullAdmin', false);
              this.snackbar.isUpdated = false;
            }

            if(socket.updateFullAdmin) {
              this.$store.commit('updateFullAdmin', true);
              this.getFullAdmin();
            }
          }

          if(socket.request == 'updateTsuikaData' || socket.request == 'hrdCancellation') {
            this.getTsuikaData();
          }

          if(socket.request == 'updateEmailMaster') {
            this.getEmail();
          }

          if(socket.request == 'updateIndustryMaster') {
            this.loadCategoryData();
          }

        }

        if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {
          if(socket.request == 'hrdReason') {
            this.getTsuikaData();
          }
        }
      }
    }
  },
  data: () => ({
    cntPendingAssess: 0,
    cntDissapproved: 0,
    lastUserUpdate: '',
    loadMore: false,
    faxMasked: 'XXX-XXX-XXXX',
    edit: {},
    editAccountSettings: false,
    warningDialog: false,
    snackbar: {
      isUpdated: false,
      color: "blue-grey-darken-2",
      text: "There's a new update. Please click 'REFRESH'.",
    },
    OrderIndustry:"",
    Language: [
      { title: "English", lang: "en" },
      { title: "Japanese", lang: "ja" },
    ],
    myDrawer: true,
    navigation: [
      {
        title: "navigation.home",
        icon: "mdi-home-outline",
        to: "/",
        meta: {
          id: 2
        }
      },
      {
        title: "navigation.gc",
        icon: "mdi-list-box-outline",
        to: "/gc_form",
        meta: {
          id: 6
        }
      },
      {
        title: "orderHistoryPage.orderHistory",
        icon: "mdi-clipboard-text-clock-outline",
        to: "/order_history",
        meta: {
          id: 12
        }
      },
      {
        title: "navigation.emailMaster",
        icon: "mdi-at",
        to: "/email_master",
        meta: {
          id: 3
        }
      },
      {
        title: "navigation.industryMaster",
        icon: "mdi-file-settings-outline",
        to: "/industry_master",
        meta: {
          id: 4
        }
      },
      
    ],
    accessSettings: [
      {
        title: "navigation.admin",
        icon: "mdi-account-check-outline",
        to: "/admin_settings",
        meta: {
          id: 7
        }
      },
      {
        title: "navigation.user",
        icon: "mdi-account-group-outline",
        to: "/user_settings",
        meta: {
          id: 8
        }
      },
      {
        title: "navigation.fullAdmin",
        icon: "mdi-account-cog-outline",
        to: "/full_admin",
        meta: {
          id: 9
        }
      },
      {
        title: "toolbar.accessView",
        icon: "mdi-monitor-eye",
        to: "/access_view",
        meta: {
          id: 10
        }
      },
      {
        title: "navigation.minimalLeadTime",
        icon: "mdi-calendar-clock",
        to: "/minimal_lead_time",
        meta: {
          id: 11
        }
      }
    ],
    activeLang: '',
    isFullAdmin: 0,
    isDisabled: false,
  }),
  computed: {
    ...mapState(["userInfo", "translation", "loggedInUser", "tsuikaUserData", "accessRightData", "tsuikaData", "fullAdminData","versionData"]),
    versionNumber(){
      const firstVersion = this.versionData.find(item => item.version);
  return firstVersion ? firstVersion.version : '';
    },
    filteredNavigation() {
      // let user = this.loggedInUser;
      let filteredArray = this.navigation.filter(route => {
        if(Object.keys(this.loggedInUser).length) {
          if(this.loggedInUser.access.includes(route.meta.id)) {
            return route;
          }

        }
      })
      return filteredArray;
    },
    filteredAccessSettings() {
      const user = this.loggedInUser;
      let accessSettings = [];
      const fullAdmin = 12; //for restriction in full admin
      // const fullAdmin = 11; //for restriction in full admin  // REVIEW for hide orderHistory

      if(Object.keys(user).length) {
        if(user.access.length == fullAdmin) {
            accessSettings = this.accessSettings;
          }
      }
      return accessSettings;

    },
    getMergeArray() {
      let data = this.tsuikaData
      let userData = this.loggedInUser;
      let senderArray = [];
      let receiverArray = [];
      let mergeArray = [];

      for(let i = 0; i < data.length; i++) {
        if(data[i].user_email == userData.email && data[i].user_name == userData.user_name) {
          senderArray.push(data[i]);
        }

        if(data[i].assigned_to == userData.user_id) {
          receiverArray.push(data[i]);
        }
      }

      if(senderArray.length && receiverArray.length) {
        mergeArray = senderArray.concat(receiverArray).sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        
        return mergeArray

      }

      if(senderArray.length && !receiverArray.length) {
        mergeArray = senderArray
        return mergeArray;
      }

      if(receiverArray.length && !senderArray.length) {
        mergeArray = receiverArray
        return mergeArray;
      }

      return mergeArray;

    },

  },
  methods: {
    ...mapActions(["getTranslate", "getTsuikaUsers", "getAccessRights", "getTsuikaData", "getFullAdmin", "getEmail", "loadCategoryData","getVersion"]),
    gcManual(){
      let file = 'GCUserManual.pdf'
      this.getFileUrlManual(file)
    },
    hrdManual(){
      let file = 'HRDUserManual.pdf'
      this.getFileUrlManual(file)
    },
    editAccount(data){
      this.edit.tempUserID = data.user_id;
      this.edit.tempUserName = data.user_name;
      this.edit.tempPhone = data.phone;
      this.edit.tempFaxNumber = data.fax_number;

      this.editAccountSettings = true;
    },
    updateUserData(){
      let data = this.loggedInUser
      this.edit['user_name'] = data.user_name;

      axios({
        method: 'post',
        url: `${window.api}/mysql/user-data/insert`,
        data: this.edit,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        if(res.data) {
          let newObject = {
            access: data.access,  
            account_status: data.account_status,
            account_type: data.account_type,
            email: data.email,
            fax_number: this.edit.tempFaxNumber,
            phone: this.edit.tempPhone,
            sub_group: data.sub_group,
            user_id: data.user_id,
            user_name: data.user_name,
          }

          this.$store.commit("LOGGEDIN_USER", newObject);
          location.reload();
        }
      })
    },
    logout() {
      this.snackbar.isUpdated = false;
      this.$store.commit("LOGGEDIN_USER", {});
      this.$store.commit('prevConstructionCode', null);
      this.$store.commit('hasNewUpdate', false);
      this.$store.commit('updateFullAdmin', false);
      this.$router.push("/login");
      Swal.fire({
        toast: true,
        position: "center-end",
        icon: "success",
        title: "Logout Sucessfully!",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    LanguageSwitcher(lang) {
      this.$i18n.locale = lang;
      this.getTranslate(lang);
    },
    manageDrawer() {
      this.myDrawer = !this.myDrawer;
      this.OrderIndustry = false;
    },
    closeMainDrawer() {
      this.myDrawer = true;
      this.OrderIndustry = false;

    },
    englishLang(lang){
      this.activeLang = 'en';
      this.$i18n.locale = lang;
      this.getTranslate(lang);

      
    },
    japaneseLang(lang){
      this.activeLang = 'ja';

      this.$i18n.locale = lang;
      this.getTranslate(lang);



    },
    async refreshPage() {
      var newUserData = Object.assign({}, this.loggedInUser);


      if(this.$store.state.updateFullAdmin) {
        this.$store.commit('hasNewUpdate', false);
        this.$store.commit('updateFullAdmin', false);
        let isFullAdmin = this.fullAdminData.find(rec => rec.user_id == this.loggedInUser.user_id);
        
        if(isFullAdmin) {
          newUserData.access.length = 0;
          newUserData['access'] = [3, 4, 5, 6, 7, 8, 9, 5, 2, 10] //for full admin
        } else {
          newUserData.access.length = 0;
          var accessRights = [];
          const industryCategory = [5];
          const homePage = [2];

          accessRights = this.accessRightData.filter(access => {
            if(access.access_right != 3) {
              return access.account_type == newUserData.account_type && access.sub_group == newUserData.sub_group
            }
          }).map(m => m.master_id);

          if(accessRights.includes(4)) {
            accessRights = accessRights.concat(industryCategory)
          }
          accessRights = accessRights.concat(homePage)
          newUserData.access = accessRights;
        }

        this.$store.commit("LOGGEDIN_USER", newUserData);
        this.$router.push('/');
        location.reload(true);
      } else {
        this.$store.commit('hasNewUpdate', false);
          const activeAccessData = this.accessRightData.filter(access => access.access_right != 3);
          const industryMaster = 4;
          const industryCategory = [5];
        
          if(Object.keys(newUserData).length) {
            if(newUserData.access.length != 10) {
              if(this.lastUserUpdate != newUserData.user_id) {
                const homePageId = [2];

                let newAccess = activeAccessData.filter(active => {
                  if(active.access_right != 3) {
                    if(active.account_type == newUserData.account_type && active.sub_group == newUserData.sub_group) {
                      return active;
                    }
                  }
                }).map(m => m.master_id);

                newUserData.access.length = 0
                newUserData.access = homePageId.concat(newAccess);

                if(newUserData.access.includes(industryMaster)) {
                  newUserData.access = newUserData.access.concat(industryCategory);
                } 

                this.$store.commit("LOGGEDIN_USER", newUserData);
                this.$router.push('/');
                location.reload(true);
              }
            } else {
                location.reload(true);
            }
          }
      }
    },


  },
  created() {
    this.getVersion();
    if (this.translation != null) {
      this.$i18n.locale = this.translation;
    }



    if(performance.getEntriesByType("navigation")[0].type == "reload") {
      this.$store.state.editMode = false;

      if(!this.$store.state.hasNewUpdate) {
        this.snackbar.isUpdated = false;
      } else {
        this.snackbar.isUpdated = true;
      }
      


    }

  

    // NOTE: Gc Tsuika Datas
    this.$store.dispatch('getUserNumberData');
    this.$store.dispatch('getAccessRights');


    // NOTE: Gc Portal (Local and Test Bucket)
    // this.$store.dispatch('getTsuikaUsers');
    this.$store.dispatch('getSubGroup');
  },
};
</script>
<style scoped>

#app_bar {
  background-color: #0C2D48;
}

.v-navigation-drawer .v-list-item__content{
	padding: 0px !important;
}

.v-navigation-drawer .v-list-item--active{
	background: #e7effd;
	color: rgb(3, 31, 97);
}

.v-list-item-group .v-list-item--active {
    color: black;
}

.v-navigation-drawer .v-list-item{
	height: 32px !important;
	font-size:  12px !important;
}

.v-navigation-drawer .v-list-item__title{
	font-size: 12px !important;
}


</style>>


