require('./plugins/style.css')
require('./bootstrap')
import './registerServiceWorker'
import "ant-design-vue/dist/antd.css"; //for ant design
import helpers from './helpers';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import myMixin from "./plugins/myMixin";
import VueTheMask from 'vue-the-mask'
import i18n from "./vue-i18n";
import Antd from "ant-design-vue"; //for ant design
import io from 'socket.io-client';

const socket = io(process.env.VUE_APP_SOCKET_IO_CONNECTION, {
  transport: ["websocket"]
})

Vue.prototype.$socket = socket;
import VueSocketIO from 'vue-socket.io';

Vue.config.productionTip = false

Vue.use(new VueSocketIO({
  connection: io(process.env.VUE_APP_SOCKET_IO_CONNECTION, {
    transport: ["websocket"]
  }), //options object is Optional
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  }
}))

Vue.use(VueTheMask)
Vue.use(myMixin);
Vue.use(Antd);



// Vue.config.productionTip = false

new Vue({
  i18n,
  Antd,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

const plugins = {
  install (Vue) {
      Vue.$helpers = helpers
      Vue.prototype.$helpers = helpers
  }
}
Vue.use(plugins);
