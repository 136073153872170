import { mapMutations, mapState, mapActions } from "vuex";

import AWS from 'aws-sdk'; //uploading/downloading of data
// import S3 from 'aws-s3' //uploading of data
import * as binconv from 'binconv'
const myMixin = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        api: process.env.VUE_APP_URL,
        upload_config:{
          region: process.env.VUE_APP_DEFAULT_REGION,
          accessKeyId: process.env.VUE_APP_ACCESS_KEY,
          secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY
        },
        awsCredentials: {
            accessKeyId: process.env.VUE_APP_ACCESS_KEY,
            secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY,
            region: process.env.VUE_APP_DEFAULT_REGION,
            apiVersions: {
              s3: process.env.VUE_APP_API_VERSION,
            },
          },
          windowLoc: {
            port: window.location.port,
            protocol: window.location.protocol,
            hostname: window.location.hostname
          }
    
      }),
      methods: {
        
        ...mapMutations([
          'STORE_USERINFO', 'STORE_ACCOUNTS', 'LOGGEDIN_USER', 
        ]),
        ...mapActions([
          'TOGGLE_EDIT_MODE', 
          'setSelectedRow',
        ]),
        async uploadToAWS(constCode, file,fileName){
          try {
            // let config = {
            //   region: "ap-southeast-1",
            //   accessKeyId: this.upload_config.accessKeyId,
            //   secretAccessKey: this.upload_config.secretAccessKey,
            // };
            AWS.config.update(this.upload_config);
            let S3Client1 = new AWS.S3({ apiVersion: process.env.VUE_APP_API_VERSION });
              for (let i = 0; i < file.length; i++) {
                let uploadParams = {
                  Bucket: process.env.VUE_APP_BUCKET_NAME,
                  Key: `situation_photos/${constCode}/${JSON.parse(fileName).find(res=>res.includes(file[i].name))}`,
                  Body: file[i],
                };
                // PUSH UPLOADING S3 OBJECT
                await S3Client1.upload(uploadParams).promise()
              }
            // function uploadS3(val) {
            //   return S3Client1.upload(val);
            // }
            return 'success';
          } catch (error) {
            return 'error';
          }
        },
        async uploadVesrionToAWS(file){
          try {
            AWS.config.update(this.upload_config);
            let S3Client1 = new AWS.S3({ apiVersion: process.env.VUE_APP_API_VERSION });
              for (let i = 0; i < file.length; i++) {
                let uploadParams = {
                  Bucket: process.env.VUE_APP_BUCKET_NAME,
                  Key: `version/${file[i].name}`,
                  Body: file[i],
                };
                // PUSH UPLOADING S3 OBJECT
                await S3Client1.upload(uploadParams).promise()
              }
              alert('Success Uploaded')
            return 'success';
          } catch (error) {
            alert('Error Upload')
            return 'error';
          }
        },
        async getFileUrl(constCode, file) {
          AWS.config.update({
            region: process.env.VUE_APP_DEFAULT_REGION,
            credentials: this.awsCredentials
          })

          return new Promise(resolve => {
            var s3 = new AWS.S3({
              Region: process.env.VUE_APP_DEFAULT_REGION,
              params: {
                bucket: process.env.VUE_APP_BUCKET_NAME,
              },
            });
            
            var options = {
              Bucket: process.env.VUE_APP_BUCKET_NAME,
              Key: `situation_photos/${constCode}/${file}`
            }

            s3.getObject(options, function(err, data) {
              if(err) console.log(err, err.stack);
              else {
                var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
                  type: 'image',
                });
                
                resolve(URL.createObjectURL(blob));
              }
            })
            
          })
        },

        async getFileUrVersion(file) {
          AWS.config.update({
            region: process.env.VUE_APP_DEFAULT_REGION,
            credentials: this.awsCredentials
          });
          // console.log("AWS config updated:", AWS.config);
          var s3 = new AWS.S3({
            Region: process.env.VUE_APP_DEFAULT_REGION,
            params: {
              bucket: process.env.VUE_APP_BUCKET_NAME,
            },
          });
          // console.log("S3 object created:", s3);
          var options = {
            Bucket: process.env.VUE_APP_BUCKET_NAME,
            Key: `version/${file}`
          };
          // console.log("Fetching file with options:", options);
          s3.getObject(options, function(err, data) {
            if (err) {
              console.error("Error fetching file:", err);
            } else {
              // console.log("File fetched successfully:", data);
              var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
                type: 'image/png',
              });
              // console.log("Blob created:", blob);
              const url = URL.createObjectURL(blob);
              // console.log("Blob URL:", url);
              window.open(url, '_blank');
            }
          });
        },

        async getFileUrlManual(file) {
          AWS.config.update({
            region: process.env.VUE_APP_DEFAULT_REGION,
            credentials: this.awsCredentials
          });
          // console.log("AWS config updated:", AWS.config);
          var s3 = new AWS.S3({
            Region: process.env.VUE_APP_DEFAULT_REGION,
            params: {
              bucket: process.env.VUE_APP_BUCKET_NAME,
            },
          });
          // console.log("S3 object created:", s3);
          var options = {
            Bucket: process.env.VUE_APP_BUCKET_NAME,
            Key: `tsuika_manual/${file}`
          };
          // console.log("Fetching file with options:", options);
          s3.getObject(options, function(err, data) {
            if (err) {
              console.error("Error fetching file:", err);
            } else {
              var blob = new Blob([data.Body], { type: 'application/pdf' });
              // console.log("Blob created:", blob);
              const url = URL.createObjectURL(blob);
              // console.log("Blob URL:", url);
              window.open(url, '_blank');
            }
          });
        },
        

        getAccessSettings() {
          let access = 0;
          const user = this.loggedInUser;
          const industryCategory = 5;
          const industryMetaId = 4;
          const fullAdmin = 12;  //for restriction in full admin
          // const fullAdmin = 11; //for restriction in full admin  // REVIEW for hide orderHistory
          
          const pageMetaId = this.$route.meta.id == industryCategory ? industryMetaId : this.$route.meta.id;
          const findAccessUser = this.accessRightData.find(res => res.account_type == user.account_type && res.sub_group == user.sub_group && res.master_id == pageMetaId);


          if(Object.keys(user).length) {
            if(user.access.length != fullAdmin) {
              if(!user.access.includes(pageMetaId)) {
                access = 0;
              } else {
                if(pageMetaId != industryMetaId) {
                  if(findAccessUser.access_right != 2) {
                    access = 0;
                  }  else {
                    access = 1;
                  }
                } else {
                  if(findAccessUser.access_right == 2) // 2 = write;
                  access = 1;
                }
                
              }
            } else {
              access = 1;
            }
          }
          
          return access; // 0 = hide; 1 = show;
        }
      },
      computed: {
        ...mapState([
          'userInfo', 
          'accounts', 
          'loggedInUser', 
          'editMode', 
          'activeMode', 
          'selectedRows',
          'isFromGc',
          'S3files',
          'prevConstructionCode'
        ]),
      },
    });
  },
};
export default myMixin;
