window._ = require('lodash');
/**
 * We indicate to use the api based on what
 * are the protocol and hostname
 */

window.axios = require('axios');

//local
// window.api = `${window.location.protocol}//${window.location.hostname}:1105`;

//aws
// window.api = `${window.location.protocol}//${window.location.hostname}`;

//NOTE Change to own IP Address 
if(window.location.hostname == 'localhost' || window.location.hostname == '10.169.142.83') {
    window.api = `${window.location.protocol}//${window.location.hostname}:1105`;
} else {
    if(window.location.hostname == 'gctsuika.hrd-s.com') {
        window.api = `https://ecwuxuy64l.execute-api.ap-southeast-1.amazonaws.com/prod`
    } else{
        window.api = `https://x25vynm662.execute-api.ap-southeast-1.amazonaws.com/tsuika-test`
    }
}

//NOTE Testing TestBucket
// if(window.location.hostname == 'localhost') {
//     // window.api = `https://ecwuxuy64l.execute-api.ap-southeast-1.amazonaws.com/prod`
//     if(window.location.hostname == 'gctsuika.hrd-s.com') {
//         window.api = `https://ecwuxuy64l.execute-api.ap-southeast-1.amazonaws.com/prod`
//     } else{
//         window.api = `https://x25vynm662.execute-api.ap-southeast-1.amazonaws.com/tsuika-test`
//     }
// }


// console.log(window.location.hostname)
