

/*=====================================================*/
/*				      forceNextTick                    */
/*=====================================================*/

// run callback function right before the next repaint
const doubleRequestAnimationFrame = (callback) => {
    requestAnimationFrame(() => {
        requestAnimationFrame(callback)
    })
}
// globally available function $forceNextTick (improved version of vue's $nextTick)
const forceNextTick = (callback) => {
    if (callback && typeof callback === 'function') {
        doubleRequestAnimationFrame(callback)
    } else {
        return new Promise(resolve => {
        doubleRequestAnimationFrame(resolve)
        })
    }
}


const activeRow = (e) => {
    forceNextTick(() => {
        const activeRow = e.closest('td')
        const rowIsActive = activeRow.classList.contains('active-row')
        const tbl = document.querySelector('.test')
        const rows = tbl.getElementsByTagName('td')

        for (let index = 0; index < rows.length; index++) {
            if (rows[index].classList.contains('active-row')) {
                rows[index].classList.remove('active-row')
                break;
            }
        }
        
        if (!rowIsActive) {
            activeRow.classList.add('active-row')
        }
    })
}

export default { activeRow, forceNextTick };